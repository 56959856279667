import React from 'react';
import { isEmpty } from 'lodash';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import {
    MYACCOUNT_LOGIN,
    MYACCOUNT_SIGNUP,
} from "../../../constants/urls"
import { LinkItem } from '../helper/myaccountLink';
import LinkMui from '@mui/material/Link';

// Icons
import Facebook from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import Google from './google.inline.svg';
import Alert from '../alert';

const TermsText = ({ dialogTermsProps, termsText, termsLink, termsLabel, privacyLink, privacyLabel, termsLinkProps }) => {
    return(
        <Typography classes={{root: "dialogTerms"}} {...dialogTermsProps}>
            {termsText} {` `}
            {(!isEmpty(termsLink)) && <LinkMui href={termsLink} {...termsLinkProps}>{termsLabel}</LinkMui>}
            {(!isEmpty(termsLink) && !isEmpty(privacyLink)) && <> and </>}
            {(!isEmpty(privacyLink)) && <LinkMui href={privacyLink} {...termsLinkProps}>{privacyLabel}</LinkMui>}.
        </Typography>
    )
}

const StyledDialogContent = (props) => {
    const {
        subtitle,
        showSocialLogin,
        showTerms,
        termsText,
        termsLinkProps,
        termsLink,
        termsLabel,
        privacyLink,
        privacyLabel,
        state,
        services,
        formActionLabel,
        dialogContentProps
    } = props

    const {
        dialogContentTitleProps,
        dialogSocialButtonProps,
        dialogSocialButtonGoogleProps,
        dialogSocialButtonFBProps,
        dialogSeparatorProps,
        dialogSeparatorSpanProps,
        dialogFormControlProps,
        dialogTermsProps,
        // dialogHelperTextProps,
        dialogActionsBtnProps,

    } = dialogContentProps

    // password field logic
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const [validation, setValidation] = React.useState({
        email: false
    });

    const handleChange = (prop) => (event) => {
        if (!event.target.value)
            setValidation({...validation, [prop]:true})
        else
            setValidation({...validation, [prop]:false})

        setValues({ ...values, [prop]: event.target.value });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        let hasError = false;
        if (!values?.email) {
            hasError = true;
            setValidation({
                email: true
            });
        }

        if(!hasError) {
            values.email = (values.email || '').toLowerCase();
            services.forgottenPassword({email: values.email});
        }
    };

    // Call social login
    const doSocialLogin = ( provider ) => {
      services.providerLogin(provider);
    }

    return(
        <DialogContent classes={{root: "dialogContent"}}>
            <DialogContentText classes={{root: "dialogContentTitle"}} {...dialogContentTitleProps}>
                {subtitle}
                <div>
                    <LinkItem className="link-login" to={MYACCOUNT_LOGIN}>Login</LinkItem><span> or </span>
                    <LinkItem className="link-login" to={MYACCOUNT_SIGNUP}> Sign Up</LinkItem>
                </div>
            </DialogContentText>
            {showSocialLogin &&
                <>
                    <Box className={"dialogSocialButtonCont"}>
                        <Button
                            {...dialogSocialButtonProps}
                            classes={{root: "dialogSocialButton"}}
                            onClick={ () => {doSocialLogin('google')}}
                        >
                            <Google {...dialogSocialButtonGoogleProps} />
                        </Button>
                        <Button
                            {...dialogSocialButtonProps}
                            classes={{root: "dialogSocialButton"}}
                            onClick={ () => {doSocialLogin('facebook')}}
                        >
                            <Facebook {...dialogSocialButtonFBProps} />
                        </Button>
                    </Box>

                    <Typography
                        {...dialogSeparatorProps}
                        classes={{root: "dialogSeparator"}}
                    >
                        <Typography classes={{root: "dialogSeparatorSpan"}} {...dialogSeparatorSpanProps}>or login with email</Typography>
                    </Typography>
                </>
            }

            <form noValidate autoComplete="off" id="myAccountResetPassEmailForm">
                <Alert type="alert" message={state.message} severity={state.error ? 'error' : 'success'} />
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="login-username"
                        type="email"
                        label="Email Address"
                        classes={{root: "dialogFormControlElem"}}
                        onChange={handleChange('email')}
                        error={validation['email']}
                        required={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>

                {showTerms && termsText &&
                    <TermsText
                        dialogTermsProps={dialogTermsProps}
                        termsText={termsText}
                        termsLinkProps={termsLinkProps}
                        termsLink={termsLink}
                        termsLabel={termsLabel}
                        privacyLink={privacyLink}
                        privacyLabel={privacyLabel}
                    />
                }

                <DialogActions classes={{root: "dialogActions"}}>
                    <Button disabled={state.loading || false} onClick={handleSubmit} {...dialogActionsBtnProps} classes={{root: "dialogActionsBtn"}} id="myAccountResetPassEmailSubmit">
                        {formActionLabel}
                    </Button>
                </DialogActions>
            </form>
        </DialogContent>
    )
};

export default StyledDialogContent;
