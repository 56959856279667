import * as React from 'react';
import DeleteAccount from './deleteAccount';
import { Grid } from '@mui/material';

const UserDeleteAccount = props => {
    return (
        <Grid container spacing={8}>
            <Grid item xs={12} md={8}>
                <DeleteAccount props={props} />
            </Grid>
        </Grid>
    )
}

export default UserDeleteAccount;
