export default {
    "props": {
        "profileChangePasswordProps": {
            
        },
        "data": {
            "submitLabel": "Delete Account"
        },
        "formTerms": "<b>*Note: </b><i>Deleting your account will remove all your information from our database. This cannot be undone.</i>"
    },
    "sx": (theme) => ({
        "& .MuiAlert-standard": {
            "marginBottom": theme.spacing(4),
        },
        "& .globalForm-actionContainer": {
            "& .globalForm-formButton": {
                "width": "50%",
            },
        }
    })
}