export default {
    "props": {
        "showValuation": true,
        "showContactInsteadOfValaution": true
    },
    "sx": {
        "& .contactButton": {
            "background": "red"
        }
    }
}
