import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Grid from '@mui/material/Grid';
import SignUp from "../modals/SignUp";
import GoogleOneTap from "./../../GoogleOneTap";
import { isAuthenticated } from './../../../services/store/utils';

const MyAccountLogin = withSubtheme((props) => {

    const authUser = isAuthenticated();
    return (
        <Grid container>
            <Grid item xs={12}>
                <SignUp onClose={() => void 0} />
                {(!authUser) && (
                    <GoogleOneTap />
                )}
            </Grid>
        </Grid>
    )
}, 'signUpPage')

export default React.memo(MyAccountLogin);
