import * as React from 'react';
import { withSubtheme } from '../../../../StarberryComponentsMui';

import { getUser } from "../../../../services/store/utils"
import { useDeleteAccountMutation } from '../../../../redux/services/profile'
import { collectResponseMessage } from '../../../../redux/utils'
import CircularProgress from "../../progress/CircularProgress";

import ComponentLayout from '../../ComponentLayout';
import formFields from "../../forms/Forms/deleteAccount"
import PasswordResetForm from "../../forms"
import { trackProfile } from "../../helper/eventTracking"
import {
    doLogout
  } from '../../../../services/store/utils';

import defaults from './defaults';

const UserChangePasswordComponent = withSubtheme((props) => {
    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        profileChangePasswordProps,
        // ...rest
    } = props;

    const ref = React.useRef(null)
    const [deleteAccount, result, isLoading] = useDeleteAccountMutation();
    const { error, isError, isSuccess } = result;
    const messageDialog = collectResponseMessage(result, error, isError, "Account has been deleted!. Your session will be clear shortly.");

    React.useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                doLogout()
            }, 3000);
        }
    }, [isSuccess])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (ref.current.checkValidity() !== false) {

            const formsdata = ref.current;
            const values = {
                id: getUser().uid,
            }
            Object.keys(formsdata).map(key => {
                if (formsdata[key].value)
                    return (
                        values[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
                    )
                else
                    return false;
            })
            if (values?.reason_for_deleting) {
                deleteAccount(values).then((res) => {
                    trackProfile({
                        eventLabel: 'Delete Account'
                    })
                })
            }
        } else {
            // services.updateMessage({message:"Please fill required fields", error:true});
        }
    };

    if (isLoading || result?.status == 'pending') {
        return <CircularProgress />
    }

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
            {...profileChangePasswordProps}
        >
            <PasswordResetForm
                ref={ref}
                formFields={formFields}
                handleSubmit={handleSubmit}
                messageDialog={messageDialog}
                hideForm={isSuccess}
            />
        </ComponentLayout>
    )
}, 'profileDeleteAccount', defaults)

export default UserChangePasswordComponent;
