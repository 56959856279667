import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography"
import Actions from '../actions';
import ValuationModule from '../simpleModule/valuation';
import ContactBtn from '../property/components/contactus';
import PageTitle from '../pageTitle';
import Calendar from '../calendar';
import { PropertyActionStoreProvider, usePropertyActionState, useAuthState } from "../../../services"
import NoActions from "../progress/NoActions"
import { /*calendarDate, */getMonth, dateRange } from '../helper/dateformat';
import defaults from './calendarDefaults';
import ActionsSkeleton from "../actions/actionsSkeleton"
import "./calendar.css"


// Provider wrapper container
const MyAccountCalendarContainer = (props) => {
    return (
        <PropertyActionStoreProvider>
            <MyAccountCalendarContent {...props} />
        </PropertyActionStoreProvider>
    )
}

// Calendar Action Container wrap for enable loading
const ActionDataContainer = ({props, state, date, month}) => {
    const data = state?.calendarLists || []

    const monthName = getMonth(month ? new Date(month) : new Date());

    if (state.loading) {
        return <ActionsSkeleton title />
    }

    if (!state.loading && !data.length) {
        return (
            <NoActions
                title="There are no appointments"
                message="At the moment all the actions are closed..."
                icon="noPendingActions"
            />
        )
    }

    return (
        <Actions title={monthName} {...props} data={props?.data ? props.data : data} />
    )
}

// Calendar Action Content
const MyAccountCalendarContent = (props) => {

    const { state, services } = usePropertyActionState()
    const [date, setDate] = React.useState();
    const [month, setMonth] = React.useState();

    React.useEffect(() => {
        services.getCalendarList({/*actionDate: calendarDate(date).toString(), */...dateRange( month ? new Date(month) : new Date())}) // defaultDate send to the api
    }, [date, month]) // eslint-disable-line react-hooks/exhaustive-deps

    const getAvailableDate = React.useCallback(() => {
        // collect available dates
        let availableDate = {};
        let availableDateFinal = {}

        // For testing data purposes using calendarLists
        if (state?.calendarLists) {
            if (state.calendarLists) {
                Object.entries(state.calendarLists).map(([key, value]) => {
                    if (typeof value.value === 'string') {
                        const calDate = new Date(value.value);
                        const calDateMonth = calDate.getMonth();
                        const calDateDay = calDate.getDate();
                        if (availableDate.hasOwnProperty(calDateMonth)) {
                            availableDate[calDateMonth].push(calDateDay);
                        } else {
                            availableDate[calDateMonth] = [/*new Date().getDate(),*/ calDateDay];
                        }
                    }
                })

                // do sorting
                Object.entries(availableDate).map(([key, value]) => {
                    availableDateFinal[key] = value.sort();
                })
            }
        }
        return availableDateFinal;
    }, [date, state?.calendarLists]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} lg={6} xl={7}>
                <ActionDataContainer date={date} month={month} props={props} state={state} />
            </Grid>
            <Grid item xs={12} lg={6} xl={5}>
                <Calendar date={date} setDate={setDate} getAvailableDate={getAvailableDate} month={month} setMonth={setMonth} />
                {props?.showContactInsteadOfValaution && (
                    <Typography
                        classes={{root: "contactButton"}}
                    >
                        <ContactBtn />
                    </Typography>
                )}
                {(props?.showValuation && !props?.showContactInsteadOfValaution) && (
                    <ValuationModule />
                )}
            </Grid>
        </Grid>
    )
}

const MyAccountCalendar = withSubtheme((props) => {
    const {theme, children, className} = props;
    const { services:authServices } = useAuthState();
    const pageTitle = `Calendar`;

    React.useEffect(() => {
        authServices.updateState({pageTitle:pageTitle, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: false});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Container maxWidth="xl">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <PageTitle theme={theme} component="h1" variant="h3">{pageTitle}</PageTitle>
                </Grid>
                <Grid item xs={12}>
                    <MyAccountCalendarContainer {...props} />
                </Grid>
            </Grid>
        </Container>

    );
}, 'calendarPage', defaults)

export default React.memo(MyAccountCalendar);
