import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Flexbox from '@mui/material/Grid';
import Login from "../modals/Login";
import GoogleOneTap from "./../../GoogleOneTap";
import { isAuthenticated } from './../../../services/store/utils';

const MyAccountLogin = withSubtheme((props) => {

    const authUser = isAuthenticated();
    return (
        <Flexbox container>
            <Flexbox item xs={12}>
                <Login onClose={() => void 0} />
                {(!authUser) && (
                    <GoogleOneTap />
                )}
            </Flexbox>
        </Flexbox>
    );
}, 'loginPage')

export default React.memo(MyAccountLogin);
